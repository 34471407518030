import React, { useState } from 'react'
import './Switch.scss';

export default function Switch({ active, onChange, name }) {
  const [_active, setActive] = useState(active);
  return (
    <div className="toggleWrapper">
      <input type="checkbox" name={name} className="mobileToggle" id={name} checked={_active} onChange={(e) => {
        setActive(e.target.checked);
        onChange(e);
      }}></input>
      <label htmlFor={name}></label>
    </div>
  )
}
