import React from 'react';
import './activityIndicator.scss';
export default class ActivityInidicator extends React.Component {
  render() {
    return (
      <div className="ActivityInidicator">
        <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
      </div>
    )
  }
}

export const showActivityInidicator = () => {
  document.querySelector('.ActivityInidicator').style.display = "flex";
}
export const hideActivityInidicator = () => {
  document.querySelector('.ActivityInidicator').style.display = "none";
} 