import React, { Component } from 'react'
import './Login.css';
import { Form, Button, Row, Col, Alert } from 'react-bootstrap';
import { API } from '../../api/api';
import { GLOBALS } from '../../utils/globals';

export default class LoginScreen extends Component {
  state = {
    loading: false,
    ErrorAlert: null
  }
  _onSubmit = (e) => {
    e.preventDefault();
    this.setState({
      loading: true
    }, () => {
      const username = e.target.elements.namedItem("username").value.trim();
      const password = e.target.elements.namedItem("password").value.trim();
      if (username && password && username !== "" && password !== "") {
        API.login(username, password).then(res => {
          this.setState({ loading: false, ErrorAlert: <Alert variant="success" transition dismissible>Login Successful</Alert> });
          localStorage.setItem('token', res.data.data);
          GLOBALS.isAuth = true;
          GLOBALS.token = res.data.data;
          this.props.history.push('/dashboard');
        }).catch(e => {
        this.setState({ loading: false, ErrorAlert:<Alert variant="danger" transition dismissible>{(e.response && e.response.data) ? e.response.data.message : e.message}</Alert>  });
        })
      } else {
        alert("Enter username and password");
      }

    });

  }


  render() {
    const { loading, ErrorAlert } = this.state;
    return (
      <Row className="justify-content-md-center">
        <Col xs lg="1" />
        <Col md="5" lg="5" sm="12">

          <Form onSubmit={this._onSubmit}>
            <Form.Group controlId="loginEmail">
              <Form.Label>Username</Form.Label>
              <Form.Control type="username" name="username" placeholder="Username" required />
            </Form.Group>

            <Form.Group controlId="loginPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" name="password" placeholder="Password" required />
            </Form.Group>

            <Button variant="primary" type="submit" disabled={loading}>Login</Button>
          </Form>
          <br/>
          {ErrorAlert}
        </Col>
        <Col xs lg="1" />
      </Row>

    )
  }
}
