import React, { Component } from 'react'
import { API } from '../api/api';
import { GLOBALS } from '../utils/globals';
import { hideActivityInidicator, showActivityInidicator } from './activityIndicator'

export default class AuthGuard extends Component {
  componentDidMount() {
    GLOBALS.isAuth = false;
    showActivityInidicator();
    const token = localStorage.getItem('token');
    if (token) {
      API.checkToken(token).then(res => {
        hideActivityInidicator();
        GLOBALS.isAuth = true;
        GLOBALS.token = token;
        this.props.history.push('/dashboard');
      }).catch(e => {
        hideActivityInidicator();
        localStorage.clear();
        GLOBALS.isAuth = false;
        GLOBALS.token = null;
        this.props.history.push('/login');
      });
    } else {
      hideActivityInidicator();
      localStorage.clear();
      GLOBALS.isAuth = false;
      GLOBALS.token = null;
      this.props.history.push('/login');
    }
  }
  render = () => <></>;
}
