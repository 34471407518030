import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from "history";
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './pages/App';
import ActivityInidicator from './components/activityIndicator';
const history = createBrowserHistory({ basename: window.location.origin === "http://localhost:3000" ? '' : '/' });

ReactDOM.render(
  <Router history={history}><App /><ActivityInidicator /></Router>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
