import React, { Component } from 'react'
import { Badge, Button, Card, Col, Container, Jumbotron, ListGroup, Nav, Row, Tab } from 'react-bootstrap';
import { API } from '../../api/api'
import { hideActivityInidicator, showActivityInidicator } from '../../components/activityIndicator';
import Switch from '../../components/switch/Switch';


export default class StudentDetails extends Component {
  state = {
    loading: true,
    username: this.props.location.state.username,
    userData: null
  }
  componentDidMount() {
    showActivityInidicator();
    API.getUserDetails(this.state.username).then(res => {
      hideActivityInidicator();
      const _data = res.data.data;
      if (_data.simulation) {
        try {
          _data.simulation = JSON.parse(_data.simulation);
        } catch (e) {
          console.log(e);
        }
      }
      this.setState({
        userData: _data
      })
    }).catch(err => {
      console.log(err);
      hideActivityInidicator();
    })
  }
  _onChangePaymentState(sim, value) {
    const _userData = { ...this.state.userData };
    _userData.simulation.simulationMetadata[sim].paid = value;
    showActivityInidicator();
    API.updateSimData(_userData.username, _userData.simulation).then(res => {
      hideActivityInidicator();
      console.log(res);
      this.setState({
        userData: _userData
      })
    }).catch(err => {
      hideActivityInidicator();
      console.log(err);
    });
  }
  render() {
    const { userData } = this.state;
    return (
      userData ? <Container>
        <Container fluid className="pt-3">
          <Button onClick={this.props.history.goBack} variant="outline-primary">&#8249; Dashboard</Button>
        </Container>
        <Col className="pt-5">
          <Card>
            <Card.Header className="text-muted"> Student Details &nbsp; {userData.simulation.accountActivated ? <Badge variant="success">Activated - {userData.simulation.activationCode}</Badge> : <Badge variant="danger">Not Activated - {userData.simulation.activationCode}</Badge>}</Card.Header>
            <Card.Body>
              <Card.Title>{userData.firstname} {userData.lastname}</Card.Title>
              <Card.Text>StudentID: {userData.studentID}</Card.Text>
              <Card.Text>Username: {userData.username}</Card.Text>
              <Card.Text>Email: {userData.email}</Card.Text>
              <a href={`tel://${userData.mobile}`} variant="primary" className="btn btn-primary">Mobile: {userData.mobile}</a>
            </Card.Body>
          </Card>
        </Col>
        <br />
        <br />
        <Container fluid>
          <h1>Simulations Data</h1>
          <br />
          <Tab.Container id="left-tabs-example" defaultActiveKey={Object.keys(userData.simulation.simulationMetadata)[0]}>
            <Row>
              <Col sm={3}>
                <Nav variant="pills" className="flex-column">
                  {
                    Object.keys(userData.simulation.simulationMetadata).map((sim, index) => <Nav.Item key={sim}>
                      <Nav.Link eventKey={sim}>Simulation {index + 1}</Nav.Link>
                    </Nav.Item>)
                  }

                </Nav>
              </Col>
              <Col sm={9}>
                <Tab.Content>
                  {
                    Object.keys(userData.simulation.simulationMetadata).map((sim, index) => <Tab.Pane key={sim} eventKey={sim}>
                      <Jumbotron fluid>
                        <Container>
                          <h1>Simulation {index + 1}</h1>
                          <h5>{userData.simulation.simulationMetadata[sim].paid ? <Badge variant="success">Paid</Badge> : <Badge variant="danger">Not Paid</Badge>} &nbsp; {userData.simulation.simulationMetadata[sim].attempted ? <Badge variant="success">Attempted</Badge> : <Badge variant="danger">Not Attempted</Badge>}</h5>
                          <p><b>Score: </b>{userData.simulation.simulationMetadata[sim].score || "-"}  &nbsp; <b>Last answered: </b>{userData.simulation.simulationMetadata[sim].currentLocation || "-"} &nbsp; <b>Time Taken</b> {userData.simulation.simulationMetadata[sim].timeTaken ? userData.simulation.simulationMetadata[sim].timeTaken.hours + ":" + userData.simulation.simulationMetadata[sim].timeTaken.mins + ":" + userData.simulation.simulationMetadata[sim].timeTaken.secs : "00:00:00"}</p>
                          <Row>
                            <Col><h4><b>Update Payment Status: </b></h4></Col>
                            <Col><Switch active={userData.simulation.simulationMetadata[sim].paid} name={`switch_${sim}`} onChange={(e) => { this._onChangePaymentState(sim, e.target.checked) }} /></Col>
                          </Row>
                        </Container>

                      </Jumbotron>
                      {
                        userData.simulation[sim] ?
                          <ListGroup>
                            {
                              Object.keys(userData.simulation[sim]).map((q, i) => {
                                const { attempted } = userData.simulation[sim][q];

                                return <ListGroup.Item key={q + i}><p><b>Question:</b>{q} &nbsp; {attempted ? <Badge variant="success">Attempted</Badge> : <Badge variant="danger">Not Attempted</Badge>}</p></ListGroup.Item>
                              })
                            }
                          </ListGroup> : <></>
                      }
                    </Tab.Pane>)
                  }
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
        {/* <code><pre>{JSON.stringify(userData, undefined, 2)}</pre></code> */}
      </Container> : <></>
    )
  }
}
