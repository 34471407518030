import React, { Component } from 'react'
import { Button, Col, Container, Dropdown, DropdownButton, FormControl, InputGroup, Table } from 'react-bootstrap'
import { API } from '../../api/api';

export default class Dashboard extends Component {

  _debounce = 350;
  _debounceCtrl = null;
  _query = null;

  state = {
    searchType: "email",
    usersList: []
  }

  _onChangeQuery = (e) => {
    this._query = e.target.value;
    this._search(this._query);
  }

  _search = (query) => {
    if (query !== null && query !== "") {
      this._debounceCtrl && clearTimeout(this._debounceCtrl);
      this._debounceCtrl = setTimeout(() => {
        this._debounceCtrl = null;
        API.searchUsers(query, this.state.searchType).then(res => {
          this.setState({
            usersList: res.data.data
          });
        }).catch(err => {
          console.log(err);
        })
      }, this._debounce);
    } else {
      this.setState({ usersList: [] })
    }

  }
  render() {
    const { searchType, usersList } = this.state;
    return (
      <Container>
        <Col className="pt-5">
          <h2 className="text-primary">Simulations Admin Panel</h2>
        </Col>
        <Col className="pt-2">
          <>
            <InputGroup>
              <DropdownButton
                as={InputGroup.Prepend}
                variant="outline-secondary"
                title={searchType.charAt(0).toUpperCase() + searchType.slice(1)}
                id="input-group-dropdown-1"
                defaultValue={searchType}
              >
                <Dropdown.Item onSelect={() => { this.setState({ searchType: "username" }) }}>Username</Dropdown.Item>
                <Dropdown.Item onSelect={() => { this.setState({ searchType: "email" }) }}>Email</Dropdown.Item>
              </DropdownButton>
              <FormControl
                placeholder={"Search users by " + searchType}
                aria-label={"Search users by " + searchType}
                aria-describedby="basic-addon2"
                onChange={this._onChangeQuery}
              />
              <InputGroup.Append>
                <Button variant="primary" onClick={() => { this._search(this._query); }}>Search</Button>
              </InputGroup.Append>
            </InputGroup>
          </>
        </Col>
        {
          usersList && usersList.length > 0 ? <Col className="pt-2">
            <Container fluid style={{ height: 500, overflowY: "auto" }} className="p-0">
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Username</th>
                    <th>Email</th>
                    <th>View Details</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    usersList.map((user, index) => <tr key={user.username + index}>
                      <td>{user.firstname} {user.lastname}</td>
                      <td>{user.username}</td>
                      <td>{user.email}</td>
                      <td><Button variant="primary" onClick={() => {
                        this.props.history.push({
                          pathname: '/details',
                          state: {
                            username: user.username
                          }
                        });
                      }}>View Details</Button></td>
                    </tr>)
                  }

                </tbody>
              </Table>



            </Container>
          </Col> : <></>
        }
      </Container>
    )
  }
}
