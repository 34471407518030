import React from 'react';
import { Nav, Navbar, Button, Container } from 'react-bootstrap';
import {
  Switch,
  Route,
  withRouter,
} from "react-router-dom";
import AuthGuard from '../components/AuthGuard';
import GuardedRoute from '../components/GuardedRoute';
import { GLOBALS } from '../utils/globals';
import Dashboard from './Dashboard/Dashboard';
import LoginScreen from './Login/Login';
import StudentDetails from './StudentDetails/StudentDetails';

function App({ history }) {

  return (
    <React.Fragment>
      <Navbar bg="primary" variant="dark" fixed='top'>
        <Navbar.Brand href="/">GDBase Simulation Admin</Navbar.Brand>
        <Nav className="mr-auto">
        </Nav>
        {
          GLOBALS.isAuth ? <Button variant="outline-light" onClick={() => {
            GLOBALS.isAuth = false;
            GLOBALS.token = null;
            localStorage.clear();
            document.location.href = "/";
          }}>Logout</Button> : <></>
        }

      </Navbar>
      <Container className="pt-5 mt-3" fluid>
        <Switch>
          <Route exact path="/">
            <AuthGuard history={history} />
          </Route>
          <GuardedRoute history={history} auth={localStorage.getItem("token") == null} path="/login" component={LoginScreen} />
          <GuardedRoute history={history} auth={GLOBALS.isAuth} path="/dashboard" component={Dashboard} />
          <GuardedRoute history={history} auth={GLOBALS.isAuth} path="/details" component={StudentDetails} />
        </Switch>
      </Container>
    </React.Fragment>
  )
}

export default withRouter(App);