import axios from 'axios';
import { GLOBALS } from '../utils/globals';
const URI = "https://gdbase.be/simulation_admin/server";

const Axios = axios.create();
Axios.interceptors.request.use(request => requestHandler(request));
Axios.interceptors.response.use(res => {
  if (res.status === 401) {
    alert("Session Expired");
    localStorage.clear();
    GLOBALS.isAuth = false;
    GLOBALS.token = null;
    document.location.href="/";
  }
  return res;
});

const requestHandler = (request) => {
  request.headers['token'] = GLOBALS.token;
  return request;
}
export const API = {
  login: (username, password) => axios.post(`${URI}/adminLogin.php`, { username, password }),
  checkToken: (token) => axios.post(`${URI}/checkToken.php`, { token }),
  searchUsers: (query, type) => Axios.post(`${URI}/searchUsers.php`, { query, type }),
  getUserDetails: (username) => Axios.post(`${URI}/get-user-data.php`, { username }),
  updateSimData: (username, simulation) => Axios.post(`${URI}/update-sim-data.php`, { username, simulation })
}